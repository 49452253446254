
.btn-info {
  color: #537df1;

  &:hover {
    color: #537df1 !important;
  }

  &:active {
    color: #537df1 !important;
  }

  &:focus {
    color: #537df1 !important;
  }
}

.btn-outline-info {
  color: #537df1;
  border: 1px solid #eaeaea;

  &:hover {
    color: #537df1;
  }

  &:active {
    color: #537df1 !important;
  }

  &:focus {
    color: #537df1 !important;
  }
}

//.btn-secondary {
//  color: #ffffff;
//
//  &:hover {
//    color: #ffffff;
//  }
//}
//
//.btn-outline-secondary {
//  color: #505050;
//
//  &:hover {
//    //background-color: var(--secondary);
//    color: #505050;
//  }
//}