@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);


:root {
    --sub-menu-background-color: #eaebec;
}

.sortable-helper {
    z-index: 1000;
}

.text-center {
    text-align: -webkit-center;
    text-align: center;
}

.cursor-pointer {
    cursor: pointer;
}

.font-mono {
    font-family: "Roboto Mono", monospace !important;
}

*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 7px;
    background-clip: padding-box;
    border: 3px solid transparent;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #929292;
}


.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;
  
    :hover, :focus {
        color: var(--color-main);
        text-decoration: none;
    }
}

.modal {
    padding-left: 0 !important;
}

.guide-modal {
    margin-right: 0 !important;
    margin-left: 0 !important;
    max-width: 100% !important;
    padding-left: 0 !important;
}

.transparent-modal {
    .modal-content {
        background-color: unset;
    }
}

.rounded-modal > .modal-content {
    border-radius: 1rem !important;
}

.font-nanum {
    font-family: 'NanumSquare', sans-serif;
}